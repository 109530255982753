export const educationData = [
    {
        id: 1,
        institution: 'Openclassrooms',
        course: 'Intégrateur Web',
        startYear: '2024',
        endYear: '2023'
    },
    {
        id: 2,
        institution: 'INALCO PARIS 13',
        course: 'LLCER/ Traitement automatique des Langues /Bac+2 obtenu',
        startYear: '2019',
        endYear: '2022'
    },
    {
        id: 3,
        institution: 'Lycée Maurice Genevoix à Ingré',
        course: 'BAC. STG - spécialité marketing/ obtenu',
        startYear: '2010',
        endYear: '2011'
    },
]