import cv from '../assets/pdf/cv.pdf'
import photoHeader from '../assets/png/laptop.png';

export const headerData = {
    name: 'Sophia Ouggahi',
    title: "Intégrateur Web",
    desciption:"Un bon développeur associe avec talent la créativité, l'organisation et les compétences pour transformer des lignes de code en innovation.",
    image: photoHeader,
    resumePdf: cv
}
