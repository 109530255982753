import webgencia from '../assets/pdf/webgencia.pdf'
import booki from '../assets/imgProjets/booki.webp'
import food from '../assets/imgProjets/food.webp'
import kasa from '../assets/imgProjets/kasa.webp'
import nina from '../assets/imgProjets/nina.webp'
import qwenta from '../assets/imgProjets/qwenta.webp'

export const projectsData = [
    {
        id: 1,
        projectName: "Booki - Agence de voyage",
        projectDesc: "Création de la page d'accueil d'une agence de voyage en utilisant HTML et CSS et intégrer l'interface responsive de leur site.",
        tags: ['HTML', 'CSS'],
        code: 'https://github.com/SophiaOgg45/Booki',
        demo: 'https://sophiaogg45.github.io/Booki/',
        image: booki
    },
    
    {
        id: 2,
        projectName: "OhmyFood - Agence de gastronomie",
        projectDesc: "Création d'une application web mobile-first, d'une agence de gastronomie, avec l'intégration d'animations CSS et la maîtrise de Sass.",
        tags: ['HTML', 'CSS','Sass'],
        code: 'https://github.com/SophiaOgg45/OhMyFood',
        demo: 'https://sophiaogg45.github.io/OhMyFood/',
        image: food
    },
    {
        id: 3,
        projectName: "Kasa - Agence de locations immobilières",
        projectDesc: "Création d'une application web dynamique avec React et React Router pour une agence  de location d’appartements entre particuliers, et intégrations des animations CSS avec Sass.",
        tags: ['Javascript', 'React', 'NodeJs', 'Sass'],
        code: 'https://github.com/SophiaOgg45/Projet_Kasa',
        demo: 'https://projetkasa.web.app/',
        image: kasa
    },
    {
        id: 4,
        projectName: "Nina Carducci - Portfolio d'une photographe professionnelle",
        projectDesc: "SEO et accessibilité sont essentiels pour augmenter la visibilité d'un site web et améliorer l'expérience utilisateur, influençant directement le trafic et la satisfaction client.",
        tags: ['Lighthouse', 'WaveTools', 'Image Convert'],
        code: 'https://github.com/SophiaOgg45/Nina-Carducci',
        demo: 'https://sophiaogg45.github.io/Nina-Carducci/',
        image: nina
    },
    {
        id: 5,
        projectName: 'Qwenta - Plateforme création de Menu gastromique',
        projectDesc: "Pour une agence 'Menu Maker' by Qwenta, j'utilise Feedly pour suivre les dernières tendances, rédige les spécifications techniques et organise le projet avec un tableau Kanban pour des Sprints efficaces.",
        tags: ['Notion', 'Spécifications Techniques', 'Feedly'],
        code: 'https://giant-seal-352.notion.site/bbeebf3a6ba94b0eae6aff3b92c953f5?v=137217bf2ff54410aaed80a152e573cd',
        demo: webgencia,
        image: qwenta
    },
    
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/